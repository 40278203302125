import React from 'react';


function Pdf() {

    return (
        <div className="wedding-form-explanation">
            <a href="SeatInformation.pdf" target="_blank">PDFを開く</a>
        </div>
    );
}

export default Pdf;