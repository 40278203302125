import { useEffect, useRef, useState } from 'react';
import './WeddingCeremony.css';

function WeddingCeremony(props: any) {

    const { pageStyle, apiData } = props;
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        
    }, []);

    return (
        <div className={`${pageStyle === 1 ? 'wedding_ceremony' : 'wedding_ceremony_2'}`}>
            <div className="wedding_ceremony_card">
                <div className="title">
                    <div className="sentence">
                        <p className="english">WEDDING<br />CEREMONY</p>
                        <p className="japanese">挙式・披露宴</p>
                    </div>
                    </div>
                    <div className="information">
                    <p className="date">2024.07.20 Sat.</p>
                    <div className="details">
                        <p className="p1">挙式 - 14:00</p>
                        <p className="p2">受付 - 14:20</p>
                    </div>
                    <div className="details">
                        <p className="p1">披露宴 - 14:45</p>
                        <p className="p2">お披楽喜 - 17:00</p>
                    </div>

                    {apiData.relationship == "友人" && (
                    <div className="details">
                        <p className="p1">二次会 - 18:00</p>
                        <p className="p2">
                        <div className="btn">
                            <a href="#wedding_after_party">明治神宮前</a>
                        </div>
                        </p>
                    </div>
                    )}


                </div>
                <div className="title">
                    <div className="sentence">
                            <p className="english">ACCESS</p>
                            <p className="japanese">アクセス</p>
                    </div>
                </div>
                <iframe className="map" src="https://www.google.com/maps/d/u/0/embed?mid=1XVeg9FPr-B2s8oAKGFpAldVB0DmMlPU&ehbc=2E312F&noprof=1"></iframe>
                <div className="address">
                    <p>〒150-0001 東京都渋谷区神宮前5-31</p>
                    <p>5-31 Jingumae Shibuya-ku Tokyo 150-0001 JAPAN</p>
                </div>
            </div>
        </div>
    );
}

export default WeddingCeremony;