import React, { lazy, useEffect, useRef, useState } from 'react';
import './WeddingQuest.css';
import { LazyLoadTypes } from 'react-slick';

import WeddingQuestFirstView from './WeddingQuestFirstView'
import CountDownClock from '../wedding_materials/CountDownClock'
import WeddingQuestLastView from './WeddingQuestLastView'
import WeddingWideQuest from './WeddingWideQuest'
import WeddingNarrowQuest from './WeddingNarrowQuest'

import yuya1 from './yuya_1.gif'
import sae1 from './sae_1.gif'
import yuya2 from './yuya_2.gif'
import sae2 from './sae_2.gif'
import yuya3 from './yuya_3.gif'
import sae3 from './sae_3.gif'
import yuya4 from './yuya_4.gif'
import memory1 from './memory_1.jpg'
import memory2 from './memory_1.jpg'
import step from './step.png'
import enoshima from './enoshima.png'
import Slider from 'react-slick';

import photo1_1 from './photo_gallery/IMG_2522.jpg';
import photo1_2 from './photo_gallery/IMG_2527.jpg';
import photo1_3 from './photo_gallery/IMG_2528.jpg';
import photo1_4 from './photo_gallery/IMG_2529.jpg';
import photo1_5 from './photo_gallery/IMG_2533.jpg';
import photo1_6 from './photo_gallery/IMG_2534.jpg';
import photo1_7 from './photo_gallery/IMG_2535.jpg';
import photo1_8 from './photo_gallery/IMG_2526.jpg';
import photo1_9 from './photo_gallery/IMG_2540.jpg';
import photo1_10 from './photo_gallery/IMG_2541.jpg';

import photo2_1 from './photo_gallery/IMG_2539.jpg';
import photo2_2 from './photo_gallery/IMG_2538.jpg';
import photo2_3 from './photo_gallery/IMG_2536.jpg';
import photo2_4 from './photo_gallery/IMG_2532.jpg';
import photo2_5 from './photo_gallery/IMG_2530.jpg';
import photo2_6 from './photo_gallery/IMG_2531.jpg';
import photo2_7 from './photo_gallery/IMG_2523.jpg';
import photo2_8 from './photo_gallery/IMG_2524.jpg';
import photo2_9 from './photo_gallery/IMG_2521.jpg';
import photo2_10 from './photo_gallery/IMG_2537.jpg';
import photo2_11 from './photo_gallery/IMG_2542.jpg';
import photo2_12 from './photo_gallery/IMG_2543.jpg';

import photo3_1 from './photo_gallery/IMG_2544.jpg';
import photo3_2 from './photo_gallery/IMG_2545.jpg';
import photo3_3 from './photo_gallery/IMG_2546.jpg';
import photo3_4 from './photo_gallery/IMG_2547.jpg';
import photo3_5 from './photo_gallery/IMG_2549.jpg';
import photo3_6 from './photo_gallery/IMG_2548.jpg';
import photo3_7 from './photo_gallery/IMG_2561.jpg';
import photo3_8 from './photo_gallery/IMG_2562.jpg';
import photo3_9 from './photo_gallery/IMG_2564.jpg';
import photo3_10 from './photo_gallery/IMG_2569.jpg';


import photo4_1 from './photo_gallery/IMG_2550.jpg';
import photo4_2 from './photo_gallery/IMG_2552.jpg';
import photo4_3 from './photo_gallery/IMG_2555.jpg';
import photo4_4 from './photo_gallery/IMG_2556.jpg';
import photo4_5 from './photo_gallery/IMG_2557.jpg';
import photo4_6 from './photo_gallery/IMG_2558.jpg';
import photo4_7 from './photo_gallery/IMG_2559.jpg';
import photo4_8 from './photo_gallery/IMG_2560.jpg';
import photo4_9 from './photo_gallery/IMG_2563.jpg';
import photo4_10 from './photo_gallery/IMG_2566.jpg';
import photo4_11 from './photo_gallery/IMG_2567.jpg';
import photo4_12 from './photo_gallery/IMG_2568.jpg';


import photo5_1 from './photo_gallery/IMG_2576.jpg';
import photo5_2 from './photo_gallery/IMG_2577.jpg';
import photo5_3 from './photo_gallery/IMG_2578.jpg';
import photo5_4 from './photo_gallery/IMG_2572.jpg';
import photo5_5 from './photo_gallery/IMG_2581.jpg';
import photo5_6 from './photo_gallery/IMG_2571.jpg';
import photo5_7 from './photo_gallery/IMG_2582.jpg';
import photo5_8 from './photo_gallery/IMG_2583.jpg';
import photo5_9 from './photo_gallery/IMG_2584.jpg';
import photo5_10 from './photo_gallery/IMG_2585.jpg';
import photo5_11 from './photo_gallery/IMG_2586.jpg';
import photo5_12 from './photo_gallery/IMG_2587.jpg';


import photo6_1 from './photo_gallery/IMG_2573.jpg';
import photo6_2 from './photo_gallery/IMG_2575.jpg';
import photo6_3 from './photo_gallery/IMG_2588.jpg';
import photo6_4 from './photo_gallery/IMG_2590.jpg';
import photo6_5 from './photo_gallery/IMG_2591.jpg';
import photo6_6 from './photo_gallery/IMG_2592.jpg';
import photo6_7 from './photo_gallery/IMG_2593.jpg';
import photo6_8 from './photo_gallery/IMG_2594.jpg';
import photo6_9 from './photo_gallery/IMG_2595.jpg';
import photo6_10 from './photo_gallery/IMG_2596.jpg';
import photo6_11 from './photo_gallery/IMG_2597.jpg';
import photo6_12 from './photo_gallery/IMG_2598.jpg';
import photo6_13 from './photo_gallery/IMG_2565.jpg';





import photoFrame from './photo_frame.png';
import photoFrame2 from './photo_frame_2.jpeg';

function WeddingQuest() {

    const photos = {
        photo1_1: photo1_1,
        photo1_2: photo1_2,
        photo1_3: photo1_3,
        photo1_4: photo1_4,
        photo1_5: photo1_5,
        photo1_6: photo1_6,
        photo1_7: photo1_7,
        photo1_8: photo1_8,
        photo1_9: photo1_9,
        photo1_10: photo1_10,
        photo2_1: photo2_1,
        photo2_2: photo2_2,
        photo2_3: photo2_3,
        photo2_4: photo2_4,
        photo2_5: photo2_5,
        photo2_6: photo2_6,
        photo2_7: photo2_7,
        photo2_8: photo2_8,
        photo2_9: photo2_9,
        photo2_10: photo2_10,
        photo2_11: photo2_11,
        photo2_12: photo2_12,
        photo3_1: photo3_1,
        photo3_2: photo3_2,
        photo3_3: photo3_3,
        photo3_4: photo3_4,
        photo3_5: photo3_5,
        photo3_6: photo3_6,
        photo3_7: photo3_7,
        photo3_8: photo3_8,
        photo3_9: photo3_9,
        photo3_10: photo3_10,
        photo4_1: photo4_1,
        photo4_2: photo4_2,
        photo4_3: photo4_3,
        photo4_4: photo4_4,
        photo4_5: photo4_5,
        photo4_6: photo4_6,
        photo4_7: photo4_7,
        photo4_8: photo4_8,
        photo4_9: photo4_9,
        photo4_10: photo4_10,
        photo4_11: photo4_11,
        photo4_12: photo4_12,
        photo5_1: photo5_1,
        photo5_2: photo5_2,
        photo5_3: photo5_3,
        photo5_4: photo5_4,
        photo5_5: photo5_5,
        photo5_6: photo5_6,
        photo5_7: photo5_7,
        photo5_8: photo5_8,
        photo5_9: photo5_9,
        photo5_10: photo5_10,
        photo5_11: photo5_11,
        photo5_12: photo5_12,
        photo6_1: photo6_1,
        photo6_2: photo6_2,
        photo6_3: photo6_3,
        photo6_4: photo6_4,
        photo6_5: photo6_5,
        photo6_6: photo6_6,
        photo6_7: photo6_7,
        photo6_8: photo6_8,
        photo6_9: photo6_9,
        photo6_10: photo6_10,
        photo6_11: photo6_11,
        photo6_12: photo6_12,
        photo6_13: photo6_13
    }

    const [isWideScreen, setIsWideScreen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // 3秒後にローディング画面を非表示にする
        const timeoutId = setTimeout(() => {
          setIsLoading(false);
          document.body.style.overflow = 'auto';
        }, 1200);    
        // コンポーネントがアンマウントされた場合にタイムアウトをクリアする
        return () => clearTimeout(timeoutId);
      }, []);
    
    useEffect(() => {
        const checkScreenSize = () => {
          setIsWideScreen(window.innerWidth < window.innerHeight);
        };
        setIsWideScreen(window.innerWidth < window.innerHeight);
    
        window.addEventListener('resize', checkScreenSize);
    
        // コンポーネントがアンマウントされた時にリスナーを削除
        return () => {
          window.removeEventListener('resize', checkScreenSize);
        };
    }, []);
    
    
  

    return (
        <div>
            {isLoading && (
                <div className="quest-loading-wrapper">
                    <div className="quest-loading">
                        <div className="loading-contents scrolled">Loading...</div>
                    </div>
                    <WeddingWideQuest photos={photos} />
                    <WeddingNarrowQuest photos={photos} />
                </div>
            )}
            {!isLoading && isWideScreen ? (
                <WeddingNarrowQuest photos={photos} />
            ) : (
                <WeddingWideQuest photos={photos} />
            )}
        </div>
    );
}

export default WeddingQuest;