import React, { ChangeEvent, FormEvent, useState } from 'react';
import './Admin.css';
import { Navigate } from 'react-router-dom';

function Admin() {

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const [adminInformation, setAdminInformation] = useState({
    userName: "",
    password: ""
  });

  const [token, setToken] = useState("");
  const [loginFlg, setLoginFlg] = useState(false);

  const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setAdminInformation({ ...adminInformation, [name]: event.target.value });
  }


  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // フォームのデフォルトの送信動作をキャンセル
    // ログインボタンがクリックされた時の処理を記述
    // API呼び出し等のロジックをここに記述
    try {
      const response = await fetch(apiEndpoint+'/admin/api/admin-login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(adminInformation),
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const token = await response.text();
      setToken(token);
      console.log('token:',token); // 成功した場合の処理
      setLoginFlg(true);


  } catch (error) {
      console.error('There was a problem with the fetch operation:', error); // エラー時の処理
  }



    console.log('Login button clicked');
  };

  if (!loginFlg) {
    return (
      <div className="admin-container">
        <div className="login-box">
          <h2>Welcome to Admin Panel</h2>
            <form onSubmit={handleLogin}>
              <input type="text" placeholder="Username or Email" className="input-field" value={adminInformation.userName} onChange={handleChange("userName")} />
              <input type="password" placeholder="Password" className="input-field" value={adminInformation.password} onChange={handleChange("password")} />
              <button className="login-button" type="submit">Login</button>
            </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="admin-container">
        <Navigate replace to="/admin/console" state={{ token }}/>
      </div>
    );
  }
  
}

export default Admin;