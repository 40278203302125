import { SetStateAction, useEffect, useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import './GuestList.css';

function UserList(props:any) {

    // apiのエンドポイント
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

    const siteEndpoint = process.env.REACT_SITE_ENDPOINT;

    const { token,setLoginFlg } = props;

    const [selectedGuestIndex, setSelectedGuestIndex] = useState(-1);

    const handleOpenModal = (index: SetStateAction<number>) => {
        setSelectedGuestIndex(index);
    };
    

    const handleCloseModal = () => {
        setSelectedGuestIndex(-1);
    };

    const guestInformation = {
        uuid: "",
        answeredFlag: false, //回答済みフラグ 
        attendance: "", // 参加or不参加
        lastNameKanji: "", // 姓（漢字）
        firstNameKanji: "", // 名（漢字）
        lastNameKana: "", // 姓（かな）
        firstNameKana: "", // 名（かな）
        dearDisplayName: "", // dearの部分の名前(アルファベット)
        postalCode: "", // 郵便番号
        address: "", // 住所
        buildingName: "", // 建物名
        phoneNumber: "", // 電話番号
        email: "", // メールアドレス
        allergies: "", // アレルギー
        message: "" // メッセージ
    };

    const [guestInformationList, setGuestInformationList] = useState([guestInformation]);

    
    interface GuestInformation {
        uuid: string;
        answeredFlag: boolean;
        attendance: string;
        lastNameKanji: string;
        firstNameKanji: string;
        lastNameKana: string;
        firstNameKana: string;
        dearDisplayName: string;
        postalCode: string;
        address: string;
        buildingName: string;
        phoneNumber: string;
        email: string;
        allergies: string;
        message: string;
    }



    const handleChangeLoginFlg = () => {
        setLoginFlg(false);
    };

    const [copiedLink, setCopiedLink] = useState("");

    // リンクをクリップボードにコピーする関数
    const copyToClipboard = (link: string) => {
        navigator.clipboard.writeText(link)
            .then(() => setCopiedLink(link))
            .catch((error) => console.error('Failed to copy:', error));
    };

    useEffect(() => {
        // APIからデータを取得する処理
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                mode: 'cors' as RequestMode, // リクエストのmodeをcorsに設定
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };

            try {
                const response = await fetch(`${apiEndpoint}/admin/api/get-guest-list/`, requestOptions);

                if (!response.ok) {
                    throw new Error('Response not OK');
                }

                const data = await response.json();
                setGuestInformationList(data);
            } catch (error) {
                console.error('API request failed:', error);
            }
        };

        fetchData(); // 非同期関数をここで呼び出す
      }, []);



    return (
        <div>
            <h2>User List</h2>
            <table>
                <thead>
                <tr>
                    <th>名前</th>
                    <th>リンク</th>
                    <th>回答済みフラグ</th>
                    <th>ご出席 / ご欠席</th>
                    <th>詳細</th>
                </tr>
                </thead>
                <tbody>
                
                {guestInformationList.map((guestInformation: GuestInformation, index) => (
                    <tr key={guestInformation.uuid}>
                        <td>{guestInformation.dearDisplayName}</td>
                        <td className="link-cell">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <a href={`${siteEndpoint}/?uuid=${guestInformation.uuid}`}>
                                    招待状リンク
                                </a>
                                <button
                                    onClick={() => copyToClipboard(`${siteEndpoint}/?uuid=${guestInformation.uuid}`)}
                                    style={{ marginLeft: '10px', cursor: 'pointer', border: 'none', background: 'none' }}
                                >
                                    <MdContentCopy />
                                </button>
                            </div>
                        </td>
                        <td>
                            {guestInformation.answeredFlag ? "回答済み" : "未回答"}
                        </td>
                        <td>{guestInformation.attendance}</td>
                        <td>
                            <button onClick={() => handleOpenModal(index)}>詳細を見る</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {selectedGuestIndex > -1 && (
                <div className="admin-modal-wrapper">
                    <div className="admin-modal-card">
                        <span className="close" onClick={handleCloseModal}>
                            &times;
                        </span>
                        <table>
                            <thead>
                                <tr>
                                    <th>項目</th>
                                    <th>データ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>表示名</td>
                                    <td>{guestInformationList[selectedGuestIndex].dearDisplayName}</td>
                                </tr>
                                <tr>
                                    <td>氏名（漢字）</td>
                                    <td>{guestInformationList[selectedGuestIndex].lastNameKanji} {guestInformationList[selectedGuestIndex].firstNameKanji}</td>
                                </tr>
                                <tr>
                                    <td>氏名（かな）</td>
                                    <td>{guestInformationList[selectedGuestIndex].lastNameKana} {guestInformationList[selectedGuestIndex].firstNameKana}</td>
                                </tr>
                                <tr>
                                    <td>郵便番号</td>
                                    <td>{guestInformationList[selectedGuestIndex].postalCode}</td>
                                </tr>
                                <tr>
                                    <td>住所</td>
                                    <td>{guestInformationList[selectedGuestIndex].address}</td>
                                </tr>
                                <tr>
                                    <td>建物名</td>
                                    <td>{guestInformationList[selectedGuestIndex].buildingName}</td>
                                </tr>
                                <tr>
                                    <td>電話番号</td>
                                    <td>{guestInformationList[selectedGuestIndex].phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td>メールアドレス</td>
                                    <td>{guestInformationList[selectedGuestIndex].email}</td>
                                </tr>
                                <tr>
                                    <td>アレルギー</td>
                                    <td>{guestInformationList[selectedGuestIndex].allergies}</td>
                                </tr>
                                <tr>
                                    <td>メッセージ</td>
                                    <td>{guestInformationList[selectedGuestIndex].message}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <button onClick={handleChangeLoginFlg}>ログアウト</button>
        </div>
    );
}
  
export default UserList;