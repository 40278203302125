import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './WeddingPhotoGallery.css';
import { LazyLoadTypes } from 'react-slick';
import photo1 from './wedding_photo_gallery_1.jpeg';
import photo2 from './wedding_photo_gallery_2.jpeg';
import photo3 from './wedding_photo_gallery_3.jpeg';
import photo4 from './wedding_photo_gallery_4.jpeg';
import photo5 from './wedding_photo_gallery_5.jpeg';
import photo6 from './wedding_photo_gallery_6.jpeg';

const images = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
];

function WeddingPhotoGallery() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: window.innerWidth <= 600 ? 1 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        adaptiveHeight: false,
        pauseOnHover: false,
        centerMode: true,
        className: "image_div",
        centerPadding: '0px',
        lazyLoad: 'ondemand'  as LazyLoadTypes
    };

    return (
        <div className="wedding-photo-gallery">
            <div className="photo-gallery-header">
                <div>Photo Gallery</div>
            </div>

            <Slider {...settings} key={0}>
                <img src={photo1} />
                <img src={photo2} />
                <img src={photo3} />
                <img src={photo4} />
                <img src={photo5} />
                <img src={photo6} />
            </Slider>
        </div>
    );
}

export default WeddingPhotoGallery;