import { SetStateAction, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import './AdminConsole.css';
import GuestList from './GuestList';
import AddGuest from './AddGuest';

function AdminConsole() {
  const location = useLocation();
  const token = location.state?.token;
  const [loginFlg, setLoginFlg] = useState(true);
  const [selectedTab, setSelectedTab] = useState('guestList');

  const handleTabChange = (tabName: SetStateAction<string>) => {
    setSelectedTab(tabName);
  };


  useEffect(() => {
    if ( token === undefined ) {
      setLoginFlg(false);
    } else {
      setLoginFlg(true);
    }
  }, []);


  if ( loginFlg ) {
    return (
      <div>
        { token != undefined && (
        <div>

          <div className="tab-container">
            <button className={selectedTab === 'addGuest' ? 'active' : ''} onClick={() => handleTabChange('addGuest')}>Add User</button>
            <button className={selectedTab === 'guestList' ? 'active' : ''} onClick={() => handleTabChange('guestList')}>User List</button>
            <button className={selectedTab === 'editGuest' ? 'active' : ''} onClick={() => handleTabChange('editGuest')}>Edit User</button>
            <button className={selectedTab === 'deleteGuest' ? 'active' : ''} onClick={() => handleTabChange('deleteGuest')}>Delete User</button>
          </div>

          <div className="tab-content">
            {/* ここに選択されたタブに応じたコンテンツを表示するコンポーネントを配置 */}
            {selectedTab === 'addGuest' && <AddGuest token={token} />}
            {selectedTab === 'guestList' && <GuestList token={token} setLoginFlg={setLoginFlg} />}
            {selectedTab === 'editGuest' && <div>後ほど実装・ユーザー編集画面</div>}
            {selectedTab === 'deleteGuest' && <div>後ほど実装・ユーザー削除画面</div>}
          </div>

        </div>
        )}
      </div>
    );
  } else {
    return (
      <Navigate replace to="/admin/arakawa" />
    );
  }
    
  }
  
  export default AdminConsole;