import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import './CountDownClock.css'

interface CountDownClockProps {
  countTime?: number;
  setCountTime: (arg0: number) => void;
  animationFlg: boolean;
}

function CountDownClock({ countTime = 0, setCountTime, animationFlg }: CountDownClockProps) {
  const [ref, inView] = useInView({
    triggerOnce: false, // 一度だけトリガー
    rootMargin: "-50px"
  });

  const [animatedStyles, setAnimatedStyles] = useSpring(() => ({
    from: { opacity: 1, transform: 'translateX(0px)'},
    to : {opacity: 1, transform: 'translateX(0px)'},
    config: { duration: 1000 },
    onRest: () => {
    },
  }));

  const calculateRemainingTime = () => {
    const targetDate = new Date('2024-07-20T12:00:00');
    const now = new Date();
    const remainingSeconds = Math.floor((targetDate.getTime() - now.getTime()) / 1000);
    return remainingSeconds > 0 ? remainingSeconds : 0;
  };

  const updateRemainingTime = () => {
    const remainingTime = calculateRemainingTime();
    setCountTime(remainingTime);
  };

  useEffect(() => {
    updateRemainingTime();

    const countDownInterval = setInterval(updateRemainingTime, 1000);

    return () => {
      clearInterval(countDownInterval);
    };
  }, [setCountTime]);

  // 要素が画面に表示されたらアニメーション開始
  useEffect(() => {
    if (animationFlg) {
      if (inView) {
        setAnimatedStyles({
          opacity: 1,
          transform: 'translateX(0px)'
        });
      } else {
        setAnimatedStyles({
          opacity: 0,
          transform: 'translateX(100px)'
        });
      }
    }
  }, [inView, setAnimatedStyles]);

  return (
    <div className="count_down_wrapper" style={{ overflowX: 'hidden' }}>
      <animated.div ref={ref} style={animatedStyles} className="count_down">
        <p className="border_bottom">Look forward to the day....</p>
        <p>to 2024. 07. 20</p>
        <div className="count_down_clock">
          <animated.div>{Math.floor(countTime / 86400)}</animated.div>
          <animated.div>{Math.floor((countTime % 86400) / 3600)}</animated.div>
          <animated.div>{Math.floor((countTime % 3600) / 60)}</animated.div>
          <animated.div>{countTime % 60}</animated.div>
        </div>
      </animated.div>
    </div>
  );
}

export default CountDownClock;