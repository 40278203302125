import { useEffect, useRef, useState } from 'react';
import './WeddingGreeting.css';

function WeddingGreeting(props: any) {

    const { pageStyle } = props;
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        
    }, []);

    return (
        <div className={`${pageStyle === 1 ? 'wedding_greeting' : 'wedding_greeting_2'}`}>
            <div className="wedding_greeting_card">
                <div className="title">
                <div className="sentence">
                    <p className="english">MESSAGE</p>
                    <p className="japanese">ご挨拶</p>
                </div>
                </div>
                <div className="message_sentence">
                <p>謹啓</p>
                <p>皆様におかれましては<br />ますますのご清祥のこととお慶び申し上げます</p>
                <p>このたび私たちは結婚することとなりました<br />つきましては　日頃お世話になっております皆様に<br />感謝を込めてささやかな小宴を催したく存じます</p>
                <p>ご多用中誠に恐縮ではございますが<br />ぜひご出席いただきたくご案内申し上げます</p>
                <p>謹白</p>
                <p>2024年5月吉日</p>
                <p>Yuya 　　　Sae</p>
                </div>
            </div>
          </div>
    );
}

export default WeddingGreeting;