import React from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './WeddingWideQuest.css';

function WeddingWideQuest(props: any) {

    const { photos } = props;

    const settings3 = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        cssEase: 'linear',
        adaptiveHeight: true,
        pauseOnHover: false,
        centerMode: true,
        className: "image_div",
        centerPadding: '0px',
        arrows: false,
        lazyLoad: 'ondemand'  as LazyLoadTypes
    };

    return (
        <div className="wide_page">
                <Slider {...settings3} className="wide_page_slider" key={2-1}>
                    <img src={photos.photo1_1} />
                    <img src={photos.photo1_2} />
                    <img src={photos.photo1_3} />
                    <img src={photos.photo1_4} />
                    <img src={photos.photo1_5} />
                    <img src={photos.photo1_6} />
                    <img src={photos.photo1_7} />
                    <img src={photos.photo1_8} />
                    <img src={photos.photo1_9} />
                    <img src={photos.photo1_10} />
                    <img src={photos.photo3_1} />
                    <img src={photos.photo3_2} />
                    <img src={photos.photo3_3} />
                    <img src={photos.photo3_4} />
                    <img src={photos.photo3_5} />
                    <img src={photos.photo3_6} />
                    <img src={photos.photo3_7} />
                    <img src={photos.photo3_8} />
                    <img src={photos.photo3_9} />
                    <img src={photos.photo3_10} />
                    <img src={photos.photo5_1} />
                    <img src={photos.photo5_2} />
                    <img src={photos.photo5_3} />
                    <img src={photos.photo5_4} />
                    <img src={photos.photo5_5} />
                    <img src={photos.photo5_6} />
                    <img src={photos.photo5_7} />
                    <img src={photos.photo5_8} />
                    <img src={photos.photo5_9} />
                    <img src={photos.photo5_10} />
                    <img src={photos.photo5_11} />
                    <img src={photos.photo5_12} />
                    <img src={photos.photo2_1} />
                    <img src={photos.photo2_2} />
                    <img src={photos.photo2_3} />
                    <img src={photos.photo2_4} />
                    <img src={photos.photo2_5} />
                    <img src={photos.photo2_6} />
                    <img src={photos.photo2_7} />
                    <img src={photos.photo2_8} />
                    <img src={photos.photo2_9} />
                    <img src={photos.photo2_10} />
                    <img src={photos.photo2_11} />
                    <img src={photos.photo2_12} />
                    <img src={photos.photo4_1} />
                    <img src={photos.photo4_2} />
                    <img src={photos.photo4_3} />
                    <img src={photos.photo4_4} />
                    <img src={photos.photo4_5} />
                    <img src={photos.photo4_6} />
                    <img src={photos.photo4_7} />
                    <img src={photos.photo4_8} />
                    <img src={photos.photo4_9} />
                    <img src={photos.photo4_10} />
                    <img src={photos.photo4_11} />
                    <img src={photos.photo4_12} />
                    <img src={photos.photo6_1} />
                    <img src={photos.photo6_2} />
                    <img src={photos.photo6_3} />
                    <img src={photos.photo6_4} />
                    <img src={photos.photo6_5} />
                    <img src={photos.photo6_6} />
                    <img src={photos.photo6_7} />
                    <img src={photos.photo6_8} />
                    <img src={photos.photo6_9} />
                    <img src={photos.photo6_10} />
                    <img src={photos.photo6_11} />
                    <img src={photos.photo6_12} />
                    <img src={photos.photo6_13} />
                </Slider>
            </div>
    );
}

export default WeddingWideQuest;