function NotFound404Page() {
    return (
    <div>
        <p>URLのuuidが正しくありません。</p>
        <p>URLを確認してください。</p>
        <p>解決しない場合は、お手数おかけしますがURLの送信元にご連絡ください。</p>
    </div>
    )
}

export default NotFound404Page;