import { useEffect, useRef, useState } from 'react';
import './WeddingAfterParty.css';

function WeddingAfterParty(props: any) {

    const { pageStyle, apiData } = props;

    useEffect(() => {
        
    }, []);

    return (
        <div className="wedding_after_party">
            <div className="wedding_after_party-title">
                <a id="wedding_after_party">After Party</a>
            </div>
            
            <div className="wedding_after_party-card">
                <div className="wedding_after_party-card-title">Place</div>

                <div className="btn">
                    <a href="https://www.sssss.club/" target="_blank">https://www.sssss.club/</a>
                </div>

                <div className="wedding_after_party-card-title">Access</div>
                <iframe className="map" src="https://www.google.com/maps/d/embed?mid=1jBHtNJWjImi5rAnOaYq_Cm3dTYWkML8&ehbc=2E312F"></iframe>
                <div className="address">
                    <p>東京都渋谷区神宮前1-14-21 バルビゾン80 2F</p>
                </div>
            </div>
        </div>
    );
}

export default WeddingAfterParty;