import { ChangeEvent, FormEvent, SetStateAction, useEffect, useState } from 'react';
import './AddGuest.css';

function AddGuest(props:any) {

    // apiのエンドポイント
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

    const { token } = props;

    const [guestInformation, setGuestInformation] = useState({
        uuid: "",
        answeredFlag: false, //回答済みフラグ 
        attendance: "", // 参加or不参加
        relationship: "友人",
        relationshipFrom: "S",
        lastNameKanji: "", // 姓（漢字）
        firstNameKanji: "", // 名（漢字）
        lastNameKana: "", // 姓（かな）
        firstNameKana: "", // 名（かな）
        dearDisplayName: "", // dearの表示名
        postalCode: "", // 郵便番号
        address: "", // 住所
        buildingName: "", // 建物名
        phoneNumber: "", // 電話番号
        email: "", // メールアドレス
        allergies: "", // アレルギー
        message: "" // メッセージ
    });


    const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setGuestInformation({ ...guestInformation, [name]: event.target.value });
    }

    const handleFriend = () => {
        setGuestInformation({ ...guestInformation, relationship: "友人" });
    };

    const handleFamily = () => {
        setGuestInformation({ ...guestInformation, relationship: "親族" });
    };

    const handleYuya = () => {
        setGuestInformation({ ...guestInformation, relationshipFrom: "Y" });
    };

    const handleSae = () => {
        setGuestInformation({ ...guestInformation, relationshipFrom: "S" });
    };

    const handleInit = () => {
        setGuestInformation({ ...guestInformation, relationshipFrom: "S", relationship: "友人", dearDisplayName: "" });
    };


    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(guestInformation);
    
            try {
                const response = await fetch(apiEndpoint+'/admin/api/add-guest', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(guestInformation),
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.text();
                console.log('Data:', data); // 成功した場合の処理

            } catch (error) {
                console.error('There was a problem with the fetch operation:', error); // エラー時の処理
            }
        
        handleInit();
    };



    return (
        <div>
            <h2>Add User</h2>
            <div className="admin-form-wrapper">
                <form onSubmit={handleSubmit}>

                    <label className="lavel">
                        お名前 （Dearのところの名前）
                        <input  type="text" className='name-box' placeholder="Yuya Arakawa" required value={guestInformation.dearDisplayName} onChange={handleChange("dearDisplayName")} />
                    </label>

                    <label className="lavel">
                        <p>関係性(友人 or 親族)</p>
                        <div className="button-wrapper">
                            <button
                                type="button"
                                className={`admin-attendance-button ${guestInformation.relationship === "友人" ? "active" : ""}`}
                                onClick={handleFriend}
                            >
                            友人
                            </button>

                            <button
                                type="button"
                                className={`admin-not-attendance-button ${guestInformation.relationship === "親族" ? "active" : ""}`}
                                onClick={handleFamily}
                            >
                            親族
                            </button>
                        </div>
                    </label>


                    <label className="lavel">
                        <p>どちらの関係者か</p>
                        <div className="button-wrapper">
                            <button
                                type="button"
                                className={`admin-attendance-button ${guestInformation.relationshipFrom === "Y" ? "active" : ""}`}
                                onClick={handleYuya}
                            >
                            侑哉
                            </button>

                            <button
                                type="button"
                                className={`admin-not-attendance-button ${guestInformation.relationshipFrom === "S" ? "active" : ""}`}
                                onClick={handleSae}
                            >
                            彩瑛
                            </button>
                        </div>
                    </label>


                    <button type="submit" className="admin-submit-botton">ゲスト作成</button>
                </form>
            </div>
        </div>
    );
}
  
export default AddGuest;