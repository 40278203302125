import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './wedding_materials/App';
import Admin from './admin/arakawa/Admin';
import AdminConsole from './admin/console/AdminConsole';
import SeatPdf from './seat_pdf/Pdf';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" Component={App} />
        <Route path="/admin/arakawa/" Component={Admin} />
        <Route path="/admin/console/" Component={AdminConsole} />
        <Route path="/wedding/seat/information/20240720" Component={SeatPdf} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
