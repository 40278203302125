import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import weddingPhoto from "./wedding_photos_1.jpeg";
import NotFound404Page from './NotFound404Page';
import LoadingSpinner from './LoadingSpinner'
import WeddingForm from './WeddingForm';
import CountDownClock from './CountDownClock'
import Header from './Header'
import WeddingFirstView from './WeddingFirstView'
import WeddingGreeting from './WeddingGreeting'
import WeddingCeremony from './WeddingCeremony' 
import WeddingAfterParty from './WeddingAfterParty' 
import WeddingPhotoGallery from './WeddingPhotoGallery'
import WeddingFormExplanation from './WeddingFormExplanation'
import WeddingQuest from '../quest/WeddingQuest'


function App() {

  const [pageStyle, setPageStyle] = useState(1);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uuid = params.get('uuid');
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const [apiData, setApiData] = useState({
    lastNameKanji: "", // 姓（漢字）
    relationship: "", // 親族 or 友人
    relationshipFrom: "", // 関係性 S or Y
    dearDisplayName: "" // dearのところに表示する名前
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const calculateRemainingTime = () => {
    const targetDate = new Date('2024-07-20T12:00:00'); // 2024年7月20日12:00
    const now = new Date();
    const remainingSeconds = Math.floor((targetDate.getTime() - now.getTime()) / 1000);
    return remainingSeconds > 0 ? remainingSeconds : 0;
  };

  const [countTime, setCountTime] = useState<number>(calculateRemainingTime)

  const [isWideScreen, setIsWideScreen] = useState(false);

  useEffect(() => {

    setIsWideScreen(window.innerWidth < window.innerHeight);

    // APIからデータを取得する処理
    fetch(apiEndpoint+'/api/wedding-form?uuid='+uuid)
      .then(response => {
        if (!response.ok) {
          throw new Error('Response not OK');
        }
        return response.json();
      })
      .then(data => setApiData(data))
      .catch(error =>  {
        console.error('API request failed:', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const rect = headerRef.current.getBoundingClientRect();
        if (window.scrollY < window.innerHeight) {
          headerRef.current.style.position = 'static';
          headerRef.current.style.zIndex = '1'; // ヘッダーのスタッキング順序を設定
          document.body.style.paddingTop = '0'; // ヘッダーの高さ分の余白を解除
          headerRef.current.style.width = '100%';
        } else if (rect.top <= 0) {
          headerRef.current.style.position = 'fixed';
          headerRef.current.style.top = '0';
          headerRef.current.style.width = window.innerWidth <= 600 ? '100%' : '70%';
          headerRef.current.style.zIndex = '2'; // ヘッダーよりも前面に表示するスタッキング順序を設定
          document.body.style.paddingTop = `${headerRef.current.offsetHeight}px`; // ヘッダーの高さ分の余白を追加
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }


  if (error || apiData === null) {
    return <NotFound404Page />;
  }

  return (
    <div className="body">
      {pageStyle == 1 ? (
        <div>
          <div className="body_background"></div>
          <WeddingFirstView pageStyle={pageStyle} />
          <div className="body_card">

              <Header ref={headerRef} setPageStyle={setPageStyle} pageStyle={pageStyle} apiData={apiData} />

              <WeddingGreeting pageStyle={pageStyle} />

              <CountDownClock countTime={countTime} setCountTime={setCountTime} animationFlg={true} />

              <WeddingCeremony pageStyle={pageStyle} apiData={apiData} />

              {apiData.relationship == "友人" && (
              <WeddingAfterParty />
              )}


              <WeddingPhotoGallery />

              <WeddingFormExplanation />


              <div className="wedding_form">
                <div className="form_end">
                  <p className="form_end_sentence">大変恐れ入りますが以降の回答や変更は<br />直接ご連絡のほどよろしくお願いいたします</p>
                  {
                  //<a target="_blank" href="https://www.google.com/" className="seat_information_link">
                  //  席次表PDF
                  //</a>
                  }
                </div>
                {
                  // <WeddingForm uuid={uuid} apiData={apiData} />
                } 
              </div>
            </div>
          </div>
      ) : (
        <WeddingQuest />
      )}
    </div>
  );
}

export default App;
