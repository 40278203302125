import { useEffect, useRef, useState } from 'react';
import './WeddingQuestFirstView.css';

function WeddingQuestFirstView() {
    const [isFocused, setIsFocused] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          if (scrollPosition > window.innerHeight) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return (
        <div className={`wedding_quest_invitation ${isScrolled ? 'quest_scrolled' : ''}`}>
            <div className="wedding_quest_middle">
              <p>WEDDING<br />INVITATION</p>
            </div>
        </div>
    );
}

export default WeddingQuestFirstView;