import { useEffect, useRef, useState } from 'react';
import './WeddingFirstView.css';
import LoadingSpinner from './LoadingSpinner'

function WeddingFirstView(props: any) {

    const { pageStyle } = props;
    const [isFocused, setIsFocused] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    const [loadingImg, setLoadingImg] = useState(true);
    const [imageLoadedImg, setImageLoadedImg] = useState(true);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        handleFocus();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          if (scrollPosition > window.innerHeight) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  // ローディングテスト
  useEffect(() => {
    const img = new Image();
        img.onload = () => {
            setLoadingImg(false);
            setImageLoadedImg(true);
        };
        img.src = './wedding_first_view.jpeg./wedding_first_view.jpeg'; // 画像のパスを指定
  }, []);


    const handleFocus = () => {
        setIsFocused(true);
        setTimeout(() => {
            document.body.style.overflow = 'auto';
        }, 2000);
    };

    return (
      <div>
        {imageLoadedImg && 
        <div className={`${pageStyle === 1 ? 'wedding_invitation' : 'wedding_invitation_2'} ${isFocused ? 'focus-image' : 'blur-image'} ${isScrolled ? 'scrolled' : ''}`}>
            <div className="wedding_middle">
              <p>WEDDING<br />INVITATION</p>
            </div>
        </div>
        }

      </div>
    );
}

export default WeddingFirstView;