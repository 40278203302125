import React, { useState } from 'react';
import './Header.css'
import menu1 from './menu1.jpg'
import menu2 from './menu2.jpg'
import menu3 from './menu3.jpg'
import menu4 from './menu4.jpg'

function Header(props: any, ref: React.LegacyRef<HTMLDivElement> | undefined) {

  const { setPageStyle,pageStyle,apiData } = props;
  const [menuNuber, setMenuNuber] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [startChangeStyle, setStartChangeStyle] = useState(false);

  const handleMenuClick = () => {
    setMenuNuber(menuNuber+1);
    if( menuNuber > 4) {
      handleTopButtonClick();
      document.body.style.overflow = 'hidden';
      setShowOverlay(true);
      setTimeout(handleStartChabgeStyle, 1000);
      setTimeout(finishStartChabgeStyle, 4000);
      setTimeout(() => {
      }, 5000);
      setTimeout(finishOverlay, 6000);
    }
  };

  const handleStartChabgeStyle = () => {
    setStartChangeStyle(true);
  };

  const finishStartChabgeStyle = () => {
    if (pageStyle === 1) {
      setPageStyle(2);
    } else {
      setPageStyle(1);
    }
    
    setStartChangeStyle(false);
    setMenuNuber(0);
  };

  const finishOverlay = () => {
    setShowOverlay(false);
  };

  const handleTopButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div ref={ref} className="header">
      <div className="dear">
        <p>Dear,<br />{apiData.dearDisplayName}</p>
      </div>
      {apiData.relationshipFrom === 'Y' && (
        <div className="menu">
            <img 
              src={menuNuber < 2 ? menu1 : menuNuber < 4 ? menu2 : menuNuber < 6 ? menu3 : menu4} 
              className="menu_photo" onClick={handleMenuClick}
            />
        </div>
      )}
      {showOverlay && (
        <div className={`menu-overlay ${ startChangeStyle? 'menu-overlay-to' : 'menu-overlay-from'}`}>
        </div>
      )}
    </div>
  );
}

export default React.forwardRef(Header);