import loadging from '../quest/yuya_4.gif'
import './LoadingSpinner.css'

function LoadingSpinner() {
    return (
        <div className="loading">
        </div>
    ) 
}

export default LoadingSpinner;