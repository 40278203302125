import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './WeddingWideQuest.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { LazyLoadTypes } from 'react-slick';

import WeddingQuestFirstView from './WeddingQuestFirstView'
import CountDownClock from '../wedding_materials/CountDownClock'
import WeddingQuestLastView from './WeddingQuestLastView'

import yuya1 from './yuya_1.gif'
import sae1 from './sae_1.gif'
import yuya2 from './yuya_2.gif'
import sae2 from './sae_2.gif'
import yuya3 from './yuya_3.gif'
import sae3 from './sae_3.gif'
import yuya4 from './yuya_4.gif'
import memory1 from './memory_1.jpg'
import memory2 from './memory_1.jpg'
import step from './step.png'
import enoshima from './enoshima.png'
import Slider from 'react-slick';

import photoFrame from './photo_frame.png';
import photoFrame2 from './photo_frame_2.jpeg';

function WeddingNarrowQuest(props: any) {

    const { photos } = props;

    const calculateRemainingTime = () => {
        const targetDate = new Date('2024-07-20T12:00:00'); // 2024年7月20日12:00
        const now = new Date();
        const remainingSeconds = Math.floor((targetDate.getTime() - now.getTime()) / 1000);
        return remainingSeconds > 0 ? remainingSeconds : 0;
    };
    const [countTime, setCountTime] = useState<number>(calculateRemainingTime)

    const [yuyaImageSrc, setYuyaImageSrc] = useState("");
    const [gifCss, setGifCss] = useState("");

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: window.innerWidth <= 600 ? 1 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        adaptiveHeight: false,
        pauseOnHover: false,
        centerMode: true,
        className: "image_div",
        centerPadding: '0px',
        arrows: false
    };
    
    useEffect(() => {
        const handleScroll = () => {
          const scrolledPixels = window.scrollY;
          const totalScroll1 = window.innerHeight * 2; 
          const totalScroll2 = window.innerHeight * 3.9;

          if (scrolledPixels < totalScroll1) {
            setYuyaImageSrc(yuya1);
            setGifCss("");
          } else if(scrolledPixels >= totalScroll2) {
            setYuyaImageSrc(yuya4);
            setGifCss("width-120");
          } else if (scrolledPixels >= totalScroll1) {
            setYuyaImageSrc(yuya2);
            setGifCss("");
          } else {
            setYuyaImageSrc(yuya1);
            setGifCss("");
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); // 空の配列を依存リストとして渡すことで、マウント時に1回だけ実行されます

    return (
        <div>
            <WeddingQuestFirstView />
            <div className="gif-container">
                <img id="yuyaImage" src={yuyaImageSrc} className={'gif left'}/>
            </div>
            <div className="stage1">
                <div className="bg-container">
                    <div className="left-background"></div>
                    <div className="contents">
                        <div>
                            <div className="photo-frame-wrapper">
                                <img src={photoFrame} className="photo-frame"/>
                            </div>
                            <Slider {...settings} className="cs-slider" key={1-1}>
                                <img src={photos.photo1_1} />
                                <img src={photos.photo1_2} />
                                <img src={photos.photo1_3} />
                                <img src={photos.photo1_4} />
                                <img src={photos.photo1_5} />
                                <img src={photos.photo1_6} />
                                <img src={photos.photo1_7} />
                                <img src={photos.photo1_8} />
                                <img src={photos.photo1_9} />
                                <img src={photos.photo1_10} />
                            </Slider>

                            <div className="photo-frame-wrapper second-photo">
                                <img src={photoFrame} className="photo-frame"/>
                            </div>
                            <Slider {...settings} className="cs-slider" key={1-2}>
                                <img src={photos.photo2_1} />
                                <img src={photos.photo2_2} />
                                <img src={photos.photo2_3} />
                                <img src={photos.photo2_4} />
                                <img src={photos.photo2_5} />
                                <img src={photos.photo2_6} />
                                <img src={photos.photo2_7} />
                                <img src={photos.photo2_8} />
                                <img src={photos.photo2_9} />
                                <img src={photos.photo2_10} />
                                <img src={photos.photo2_11} />
                                <img src={photos.photo2_12} />
                            </Slider>
                        </div>
                    </div>
                    <div className="right-background"></div>
                </div>
            </div>
            <div className="tunnel-entrance-wrpper">
                <div className="tunnel-entrance-bg">               
                    <img src={step} className="tunnel-entrance"/>
                    <img className="tunnel-entrance"/>
                </div>
            </div>
            <div className="tunnel-contents-wrapper">
                <div className="tunnel-contents">
                    <p>謹啓</p>
                    <p>皆様におかれましては<br />ますますのご清祥のこととお慶び申し上げます</p>
                    <p>このたび私たちは結婚することとなりました<br />つきましては　日頃お世話になっております皆様に<br />感謝を込めてささやかな小宴を催したく存じます</p>
                    <p>ご多用中誠に恐縮ではございますが<br />ぜひご出席いただきたくご案内申し上げます</p>
                    <p>謹白</p>
                    <p>2024年5月吉日</p>
                    <p>Yuya 　　　Sae</p>
                </div>
            </div>


            <div className="stage2">
                <div className="bg-container">
                    <div className="left-background"></div>
                    <div className="contents">
                        <div>
                            <Slider {...settings} className="cs-slider" key={1-3}>
                                <img src={photos.photo3_1} />
                                <img src={photos.photo3_2} />
                                <img src={photos.photo3_3} />
                                <img src={photos.photo3_4} />
                                <img src={photos.photo3_5} />
                                <img src={photos.photo3_6} />
                                <img src={photos.photo3_7} />
                                <img src={photos.photo3_8} />
                                <img src={photos.photo3_9} />
                                <img src={photos.photo3_10} />
                            </Slider>
                            <div className="photo-frame-wrapper">
                                <img src={photoFrame2} className="photo-frame"/>
                            </div>

                            
                            <Slider {...settings} className="cs-slider" key={1-4}>
                                <img src={photos.photo4_1} />
                                <img src={photos.photo4_2} />
                                <img src={photos.photo4_3} />
                                <img src={photos.photo4_4} />
                                <img src={photos.photo4_5} />
                                <img src={photos.photo4_6} />
                                <img src={photos.photo4_7} />
                                <img src={photos.photo4_8} />
                                <img src={photos.photo4_9} />
                                <img src={photos.photo4_10} />
                                <img src={photos.photo4_11} />
                                <img src={photos.photo4_12} />
                            </Slider>
                            <div className="photo-frame-wrapper">
                                <img src={photoFrame2} className="photo-frame"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tunnel-entrance-wrpper">
                <img src={step} className="tunnel-entrance"/>
            </div>

            <CountDownClock countTime={countTime} setCountTime={setCountTime} animationFlg={false} />
            
            <div className="tunnel-contents-wrapper-2">
                <img src={enoshima} className="enoshima"/>
            </div>

            <div className="stage3">
                <div className="bg-container">
                    <div className="left-background"></div>
                    <div className="contents">
                        <div>
                            <Slider {...settings} className="cs-slider" key={1-5}>
                                <img src={photos.photo5_1} />
                                <img src={photos.photo5_2} />
                                <img src={photos.photo5_3} />
                                <img src={photos.photo5_4} />
                                <img src={photos.photo5_5} />
                                <img src={photos.photo5_6} />
                                <img src={photos.photo5_7} />
                                <img src={photos.photo5_8} />
                                <img src={photos.photo5_9} />
                                <img src={photos.photo5_10} />
                                <img src={photos.photo5_11} />
                                <img src={photos.photo5_12} />
                            </Slider>

                            <Slider {...settings} className="cs-slider" key={1-6}>
                                <img src={photos.photo6_1} />
                                <img src={photos.photo6_2} />
                                <img src={photos.photo6_3} />
                                <img src={photos.photo6_4} />
                                <img src={photos.photo6_5} />
                                <img src={photos.photo6_6} />
                                <img src={photos.photo6_7} />
                                <img src={photos.photo6_8} />
                                <img src={photos.photo6_9} />
                                <img src={photos.photo6_10} />
                                <img src={photos.photo6_11} />
                                <img src={photos.photo6_12} />
                                <img src={photos.photo6_13} />
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <WeddingQuestLastView />
        </div>
    );
}

export default WeddingNarrowQuest;