import { useEffect, useRef, useState } from 'react';
import './WeddingQuestLastView.css';

function WeddingQuestFirstView() {
    
    return (
        <div className="wedding_quest_last">
            <div className="wedding_quest_middle">
              <p>Enjoy<br />wedding party!</p>
            </div>
        </div>
    );
}

export default WeddingQuestFirstView;