import React from 'react';
import './WeddingFormExplanation.css';


function WeddingFormExplanation() {

    return (
        <div className="wedding-form-explanation">
            <div className="wedding-form-explanation-header">
                RSPV
            </div>
            <div className="wedding-form-explanation-header_2">
                ご出席確認
            </div>

            <div className="explanation">
              <p>郵送でのご案内状に代わり　当招待状をお送りしております<br />お手数ではありますが　出席情報のご登録をお願い申し上げます</p>
              <p>また当日のお食事のご用意にあたり<br />アレルギー欄にご記入くださいますようお願い申し上げます</p>
              <p>6月12日までにご返信お願いいたします</p>
            </div>
        </div>
    );
}

export default WeddingFormExplanation;